import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Navbar = ({page}) => {
  const { strapiHeaderNavigation, strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiHeaderNavigation {
        pages {
          ... on STRAPI__COMPONENT_SHARED_PAGE {
            page {
              title
              url
            }
          }
        }
      }
      strapiGlobal {
        siteName
        siteDescription
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 160)
            }
          }
          alternativeText
        }
      }
    }
  `)

  const pages = strapiHeaderNavigation.pages
  const logo = strapiGlobal.logo
  const siteDescription = strapiGlobal.siteDescription
  const url = page.url ? page.url : '';

  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <header className="mt-8">
      <nav className="grid grid-cols-footer">
        <div class="px-6 md:pl-12 md:pr-0 md:col-span-1 col-span-3 mt-5 flex justify-between">
          <Link to="/" className="test">
            <GatsbyImage
              placeholder="none"
              image={getImage(logo.localFile)}
              alt={logo.alternativeText}
              className="md:max-w-[160px] h-auto object-contain max-w-[130px]"
            />
          </Link>
          <button className="mobile-menu-button outline-none md:hidden" name="mobile-menu-button" aria-label="Mobile Navigation" onClick={() => toggleExpansion(!isExpanded)}>
            <svg
                className=" h-12 w-12 text-black hover:text-primary "
                x-show="!showMenu"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        <div className="px-6 md:pr-12 md:pl-0 md:col-span-2 col-span-3">
          <div class="hidden md:flex w-full flex-row flex-wrap items-baseline justify-between">
            {pages.map(function (page) {
              page = page.page
              return (
                <Link className={`${ url === page.url ? `border-b-2 border-primary text-primary` : `text-black` } font-light mb-1 h-[30px] box-border transition duration-300 hover:text-primary`} to={"/" + page.url}>
                  {page.title}
                </Link>
              )
            })}
          </div>
          <div className={`${isExpanded ? `block` : `hidden`} mobile-menu mt-10`}>
            <ul className="">
              <li>
                {pages.map(function (page) {
                  page = page.page
                  return (
                      <Link className={`${ url === page.url ? `bg-primary text-white` : `transition duration-300 hover:bg-primary text-black hover:text-white font-normal` } block px-2 py-4 text-sm font-normal`} to={"/" + page.url}>
                        {page.title}
                      </Link>
                  )
                })}
              </li>
            </ul>
          </div>
          <p class="md:mt-6 mt-8 font-light uppercase text-primary text-xs md:text-base">
            {siteDescription}
          </p>
        </div>
      </nav>
    </header>
  )
}

export default Navbar
