import React from "react"
import Footer from "./footer"
import Navbar from "./navbar"

const Layout = ({ page, children }) => {

  return (
    <div class="flex min-h-screen flex-col justify-start bg-white text-neutral-900">
      <Navbar page={page} />
      {children}
      <Footer page={page} />
    </div>
  )
}

export default Layout
