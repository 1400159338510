import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Footer = ({ page }) => {
  const currentYear = new Date().getFullYear()

  const { strapiFooterNavigation } = useStaticQuery(graphql`
    query {
      strapiFooterNavigation {
        pages {
          ... on STRAPI__COMPONENT_SHARED_PAGE {
            page {
              title
              url
            }
          }
        }
      }
    }
  `)

  const pages = strapiFooterNavigation.pages
  const url = page.url ? page.url : ""

  return (
    <footer className="mb-8 md:mb-32 mt-auto grid grid-cols-footer grid-rows-1 bg-white text-sm text-neutral-700">
      <div className="col-start-0 col-span-3 md:col-span-2 md:col-start-2 px-6 md:px-0 md:pr-12">
        <div className="flex flex-row flex-wrap items-baseline justify-between">
          {pages.map(function (page) {
            page = page.page
            return (
              <Link className={`${ url === page.url ? `text-primary` : `text-black` } font-medium uppercase text-sm`} to={"/" + page.url}>
                {page.title}
              </Link>
            )
          })}
        </div>
      </div>
    </footer>
  )
}

export default Footer
