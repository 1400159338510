import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BlockSlider from "./block-slider"
import BlockRichText from "./block-rich-text"

const BlockProject = ({ data }) => {

  return (
    <section className="grid grid-cols-9 mb-20">
      <div class="pl-12 md:col-span-2 md:block hidden">
        <h1 class="text-primary font-medium text-[1.625rem] uppercase whitespace-pre-wrap md:block hidden leading-none">{data.title}</h1>
      </div>
      <div class="md:col-span-5 col-span-9 md:col-start-3 col-start-1 px-6 md:px-0">
        <BlockSlider data={{files: data.slider}}/>
        <h1 className="text-primary font-medium md:text-5xl text-[1.625rem] whitespace-pre-wrap md:hidden block mt-10">{data.title}</h1>
        <BlockRichText data={data}/>
      </div>
    </section>
  )
}

export default BlockProject
