import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlockRichText = ({ data }) => {
  data.richTextBody?.medias.forEach((image) => {
    data.richTextBody.data.childMarkdownRemark.html =
      data.richTextBody.data.childMarkdownRemark.html.replace(
        image.src,
        image.localFile.childImageSharp.gatsbyImageData.images.fallback.src
      )
  })
  return (
    <div className="prose w-full">
      <div
        className="richtext strong-primary leading-tight"
        dangerouslySetInnerHTML={{
          __html: data.richTextBody.data.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

export default BlockRichText
