import React from "react"
import BlocksRenderer from "./blocks-renderer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "./layout"
import Seo from "./seo"

const PageLayout = ({ seo = {}, page = {} }) => {
  return (
    <Layout as="page" page={page}>
      <Seo seo={seo} />
      <main class="mb-8 mt-8 md:mb-32">
        <header className="mb- grid grid-cols-footer">
          <div class="col-span-3 col-start-1 px-6 md:col-span-1 md:col-start-2 md:px-0">
            {page.title !== "Home" && (
              <h1 className="mb-6 md:mb-20 md:text-5xl text-3xl font-medium uppercase text-primary">
                {page.title}
              </h1>
            )}

            <GatsbyImage
              image={getImage(page?.cover?.localFile)}
              alt={page?.cover?.alternativeText}
            />
          </div>
        </header>
        <div className="mt-6 md:mt-10">
          <BlocksRenderer blocks={page.blocks || []} />
        </div>
      </main>
    </Layout>
  )
}

export default PageLayout
